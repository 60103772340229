
import Vue, { PropType } from 'vue'
import { ManualCommunication } from '@/types/GCB2'
import {
    TASKLIST_ACTION_TYPES,
    TASKLIST_COMMUNICATIONS_TYPES,
    TASKLIST_STATUSES,
} from '../../../../vars/GCB2'

import taskListActions from '@/components/chisai/GCB2/taskList/actions'
import ModaDialog from '../taskList/ModaDialog.vue'
import { Project } from '../../../../types/main'
import SendMessageDialog from '../taskList/SendMessageDialog.vue'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import useSendMessageDisabled from '../hooks/useSendMessageDisabled'
import { getActivation } from '@root/src/api/chisai/GCB2'
import { useModalStore } from '@root/src/store/stores/modal'
import { fetchConnector } from '@root/src/api/projects'
import { getAuthStatus } from '@root/src/api/networkFixer'
import { getConnectionStatus } from '@root/src/api/connectors/yClients'

export default Vue.extend({
    components: { ModaDialog, SendMessageDialog },
    props: { manualCommunication: { type: Object as PropType<ManualCommunication> } },
    data: () => ({
        TASKLIST_ACTION_TYPES,
        TASKLIST_STATUSES,
        isPostponeDialog: false,
        isModaDialog: false,
        activeItem: null as null | any,
        changeCommentMode: null as null | 'ONLY_COMMENT' | 'DONT_REMIND',
        isChangeCommentDialog: false,
        isSendMessageDialog: false,
        sendMessageErrors: null as any,
    }),

    computed: {
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        showActionModalOnContact(): boolean {
            const gcb2Connector = this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            )
            return Boolean(gcb2Connector!.showActionModalOnContact)
        },
        userId(): string {
            return this.$store.getters.myUser.sub
        },
        loadingActionsCommunicationIds(): string[] {
            return this.$store.state.GCB2.taskList.loadingActionsCommunicationIds
        },
        callButtonDisabled(): boolean {
            return this.$store.state.GCB2.taskList.disabledCallCommunicationIds.includes(
                this.manualCommunication.communicationId
            )
        },
        buttonsDisabled(): boolean {
            if (this.manualCommunication && !status) {
                return this.loadingActionsCommunicationIds.includes(this.manualCommunication.communicationId)
            } else {
                return false
            }
        },
        sendMessageError(): string | undefined {
            if (this.sendMessageErrors) {
                return this.sendMessageErrors.findError(this.manualCommunication)
            }
        },
        modalStore(): any {
            return useModalStore()
        },
    },

    methods: {
        isActionAvailable: taskListActions.isActionAvailable,
        openModaDialog(item: any) {
            this.activeItem = item
            this.isModaDialog = true
        },
        openActionModal(item: ManualCommunication, actionType: string) {
            this.gcb2Store.openActionModal({
                actionType,
                communicationId: item.communicationId,
                opened: true,
            })
        },
        openChangeCommentDialog(item: any) {
            this.activeItem = item
            this.changeCommentMode = 'ONLY_COMMENT'
            this.isChangeCommentDialog = true
        },
        openDontRemindDialog(item: any) {
            this.activeItem = item
            this.changeCommentMode = 'DONT_REMIND'
            this.isChangeCommentDialog = true
        },
        async dontContactAction(item: any) {
            await taskListActions.dontContact(this, item)
        },
        async remindAction(item: any) {
            await taskListActions.remind(this, item)
        },
        async makeCall(item: any) {
            await taskListActions.makeCall({ vueInstanse: this, item, project: this.project })
        },
        async contact(item: any) {
            if (this.showActionModalOnContact) {
                await this.openActionModal(item, TASKLIST_ACTION_TYPES.CONTACT)
            } else {
                await taskListActions.contact({
                    vueInstanse: this,
                    item,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.NO,
                })
            }
        },
        openSendMessageDialog(item: any) {
            this.activeItem = item
            this.isSendMessageDialog = true
        },
        async checkBots(item: any) {
            let preferedBotInActivation = null
            let oldBotDataFromRequest = null
            let newBotDataFromRequest = null
            let yClientsConnectorFromRequest = null
            const oldBotInConnectors = this.project.connectors.find(
                connector => connector.connectorType === 'whatsapp'
            )
            const newBotInConnectors = this.project.connectors.find(
                connector => connector.connectorType === 'whatsappBasis'
            )
            const yClientsConnector = this.project.connectors.find(
                connector => connector.connectorType === 'yClients'
            )
            const { data, error } = await getActivation({
                projectId: this.project.id,
                id: item.activationId,
            })
            if (newBotInConnectors) {
                const { data, error } = await getAuthStatus({ connectorId: newBotInConnectors.connectorId })
                if (!error) {
                    newBotDataFromRequest = data
                }
            }
            if (oldBotInConnectors) {
                const { data, error } = await fetchConnector(oldBotInConnectors.connectorId)
                if (!error) {
                    oldBotDataFromRequest = data
                }
            }
            if (yClientsConnector) {
                const { data, error } = await getConnectionStatus(yClientsConnector.connectorId)
                if (!error) {
                    yClientsConnectorFromRequest = data
                }
            }

            if (!error) {
                if (data.extra?.preferedBot) {
                    preferedBotInActivation = data.extra?.preferedBot?.split('_')[1] || null
                }
            }
            if (
                (preferedBotInActivation === 'whatsappBasis' && newBotDataFromRequest.alive !== 'auth') ||
                (!preferedBotInActivation && newBotInConnectors && newBotDataFromRequest?.alive !== 'auth' && !oldBotInConnectors) ||
                (!preferedBotInActivation && newBotInConnectors &&
                    oldBotInConnectors &&
                    !oldBotDataFromRequest?.alive &&
                    newBotDataFromRequest?.alive !== 'auth')
            ) {
                this.modalStore.open({
                    text: `Отсутствует авторизация, отправка сообщений невозможна.\nПовторите авторизацию или выберите другого бота в настройках активации.`,
                    link: [
                        `/project/${this.project.id}/connectorSettings/${newBotInConnectors.connectorId}`,
                    ],
                    linkText: ['Перейти в настройки бота'],
                })
            } else if (
                (preferedBotInActivation === 'whatsapp' && !oldBotDataFromRequest?.alive) ||
                (!preferedBotInActivation && oldBotInConnectors && !oldBotDataFromRequest?.alive && !newBotInConnectors)
            ) {
                this.modalStore.open({
                    text: `Отсутствует авторизация, отправка сообщений невозможна.\nПовторите авторизацию или выберите другого бота в настройках активации.`,
                    link: [
                        `/project/${this.project.id}/connectorSettings/${oldBotInConnectors.connectorId}`,
                    ],
                    linkText: ['Перейти в настройки бота'],
                })
            } else if ((preferedBotInActivation === 'yClients' && yClientsConnectorFromRequest.status !== 'active') ||
                (!preferedBotInActivation && !oldBotInConnectors && !newBotInConnectors && yClientsConnector)
            ) {
                this.modalStore.open({
                    text: `Отсутствует авторизация, отправка сообщений невозможна.\nОбратитесь, пожалуйста, к своему боту.`
                })
             }else {
                this.openSendMessageDialog(item)
            }
        },
    },
    async created() {},
    mounted() {
        let { findError } = useSendMessageDisabled()
        this.sendMessageErrors = { findError }
    },
})
