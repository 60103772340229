var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SendMessageDialog',{attrs:{"item":_vm.activeItem},model:{value:(_vm.isSendMessageDialog),callback:function ($$v) {_vm.isSendMessageDialog=$$v},expression:"isSendMessageDialog"}}),_c('ModaDialog',{attrs:{"item":_vm.activeItem},model:{value:(_vm.isModaDialog),callback:function ($$v) {_vm.isModaDialog=$$v},expression:"isModaDialog"}}),_c('v-row',{staticClass:"ma-0"},[(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    project: _vm.project,
                    actionType: _vm.TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.openActionModal(_vm.manualCommunication, _vm.TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE)}}},[_vm._v("Изм. ожидаемую дату ")]):_vm._e(),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    actionType: _vm.TASKLIST_ACTION_TYPES.DONT_REMIND,
                    project: _vm.project,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.openActionModal(_vm.manualCommunication, _vm.TASKLIST_ACTION_TYPES.DONT_REMIND)}}},[_vm._v("Не напоминаем")]):_vm._e(),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    project: _vm.project,
                    actionType: _vm.TASKLIST_ACTION_TYPES.CONTACT,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.contact(_vm.manualCommunication)}}},[_vm._v("Связались")]):_vm._e(),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    project: _vm.project,
                    actionType: _vm.TASKLIST_ACTION_TYPES.DONT_CONTACT,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.dontContactAction(_vm.manualCommunication)}}},[_vm._v("Не связались")]):_vm._e(),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    project: _vm.project,
                    actionType: _vm.TASKLIST_ACTION_TYPES.REMIND,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.remindAction(_vm.manualCommunication)}}},[_vm._v("Напоминаем")]):_vm._e(),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    actionType: _vm.TASKLIST_ACTION_TYPES.CHANGE_COMMENT,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.openActionModal(_vm.manualCommunication, _vm.TASKLIST_ACTION_TYPES.CHANGE_COMMENT)}}},[_vm._v("Изм. комментарий")]):_vm._e(),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    actionType: _vm.TASKLIST_ACTION_TYPES.CHANGE_MODA,
                    project: _vm.project,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.openModaDialog(_vm.manualCommunication)}}},[_vm._v("Настроить цикл возврата")]):_vm._e(),_c('v-tooltip',{attrs:{"right":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),_vm.sendMessageError ? on : {}),[(
                            _vm.isActionAvailable({
                                item: _vm.manualCommunication,
                                actionType: _vm.TASKLIST_ACTION_TYPES.SEND_MESSAGE,
                                project: _vm.project,
                            })
                        )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":!!_vm.sendMessageError,"outlined":"","small":""},on:{"click":function($event){return _vm.checkBots(_vm.manualCommunication)}}},[_vm._v(" Отправка через бота ")]):_vm._e(),(
                            _vm.isActionAvailable({
                                item: _vm.manualCommunication,
                                actionType: _vm.TASKLIST_ACTION_TYPES.IN_PROGRESS_AGAIN,
                                project: _vm.project,
                                userId: _vm.userId,
                            })
                        )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":!!_vm.sendMessageError,"outlined":"","small":""},on:{"click":function($event){return _vm.checkBots(_vm.manualCommunication)}}},[_vm._v(" Переотправить ")]):_vm._e()],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.sendMessageError)+" ")])]),(
                _vm.isActionAvailable({
                    item: _vm.manualCommunication,
                    actionType: _vm.TASKLIST_ACTION_TYPES.MAKE_CALL,
                    project: _vm.project,
                    userId: _vm.userId,
                })
            )?_c('v-btn',{staticClass:"mr-2 mt-3 action-btn",attrs:{"disabled":_vm.buttonsDisabled || _vm.callButtonDisabled,"outlined":"","small":""},on:{"click":function($event){return _vm.makeCall(_vm.manualCommunication)}}},[_vm._v(" Позвонить ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }